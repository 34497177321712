.pageContainer {
  position: relative;
  background-color: #fff;
  min-height: 100vh;
}

.backgroundImage {
  position: absolute;
  top: 0;
  width: 100%;
}

.pageHeader {
  position: relative;
  top: 438px;
  left: 166px;
  width: fit-content;
}

.pageHeader h1 {
  color: #fff;
  font-family: NeutrafaceDJCBoldItalic;
  font-size: 160px;
  text-transform: uppercase;
}

.contentContainer {
  position: relative;
  margin: 576px 162px 0px 162px;
  background-color: #f2f2f2;
}

.tyreContainer {
  display: flex;
  overflow: hidden;
  height: 2400px;
}

.tyreInfo {
  display: flex;
  flex-direction: column;
  min-width: 100%;
}

.tyreInfo img {
  width: 1400px;
  margin: 0 auto;
}

.tyreText {
  width: 100%;
  margin-top: 75px;
  padding: 0 160px;
  white-space: pre-wrap;
}

.tyreText h2 {
  font-family: NeutrafaceDJCBold;
  font-size: 76px;
  text-transform: uppercase;
  color: #00a3e0;
}

.tyreText p {
  margin-top: 50px;
  font-family: NeutrafaceDJCLight;
  font-size: 60px;
  line-height: 80px;
}

.tyreIcons {
  align-self: flex-end;
  display: flex;
  justify-content: space-evenly;
  background-color: #fff;
  margin-top: 150px;
}

.activeTyre::after {
  content: "";
  margin: 0 auto;
  display: block;
  position: relative;
  width: 50%;
  border-bottom: 3px solid #e40046;
}
