.pageContainer {
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.headingContainer {
  padding: 398px 0 86px 148px;
}

.headingContainer h1 {
  color: #fff;
  font-size: 120px;
  font-family: NeutrafaceDJCBoldItalic;
}

.blueText {
  color: #00a3e0;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  margin-bottom: 62px;
  transform: translateX(-3000px);
}
