.nav {
  position: fixed;
  background-color: #fff;
  height: 280px;
  width: 100%;
  z-index: 9999;
}

.backArrow {
  position: absolute;
  left: 160px;
  top: 50%;
  transform: translateY(-50%);
  height: 90px;
}

.navText {
  position: absolute;
  right: 150px;
  top: 50%;
  transform: translateY(-50%);
  height: 90px;
}
