.pageContainer {
  background-color: #041e42;
  min-height: 100vh;
  overflow: hidden;
}

.pageHeader {
  position: fixed;
  z-index: 1;
}

.trackMedia {
  position: relative;
  background-color: #041e42;
}

.cityImage {
  width: 100%;
}

.trackName {
  position: absolute;
  top: 408px;
  left: 166px;
  color: #fff;
  font-size: 160px;
  text-transform: uppercase;
  font-family: "NeutrafaceDJCBoldItalic";
  padding-bottom: 25px;
}
.trackName::after {
  content: "";
  display: block;
  position: relative;
  padding-top: 50px;
  width: 230px;
  border-bottom: 3px solid #e40046;
}

.trackMap {
  position: absolute;
  top: 675px;
  left: 75px;
  width: 94%;
  padding: 45px;
}

.trackInfoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 44.5vh;
  background-color: #041e42;
  color: #fff;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 150px;
  padding-left: 166px;
}

.weekendInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-right: 280px;
  height: 100%;
  line-height: 120px;
  border-right: 5px solid #e40046;
}

.weekendInfo h2 {
  font-size: 110px;
  text-transform: uppercase;
  font-family: NeutrafaceDJCBoldItalic;
}

.dayInfo {
  margin-top: 110px;
}

.dayInfo h3 {
  color: #00a3e0;
  text-transform: uppercase;
  font-family: NeutrafaceDJCBold;
  font-size: 79px;
  line-height: 103px;
}

.dayInfo p {
  font-size: 65px;
  font-family: NeutrafaceDJCBook;
  text-transform: uppercase;
}

.dayInfo span {
  font-family: NeutrafaceDJCBold;
}

.trackInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 166px;
  height: 100%;
  line-height: 100px;
}

.infoText {
  font-size: 75px;
  font-family: NeutrafaceDJCBook;
}

.blueText {
  font-family: NeutrafaceDJCBold;
  color: #00a3e0;
}

.blueText:nth-child(3) {
  font-size: 65px;
  font-family: NeutrafaceDJCBook;
}
