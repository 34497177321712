.pageContainer {
  position: relative;
}

.backgroundImage {
  position: absolute;
  top: 0;
  width: 100%;
}

.headingContainer {
  position: absolute;
  top: 438px;
  left: 166px;
  line-height: 160px;
  color: #fff;
  font-family: NeutrafaceDJCBoldItalic;
}

.headingContainer h1 {
  text-transform: uppercase;
  font-size: 160px;
}

.blueText {
  color: #00a3e0;
}

.contentContainer {
  position: relative;
  padding-top: 889px;
}

.leaders {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 168px;
}

.leader {
  margin-bottom: 39px;
}

.leader img {
  height: 910px;
}
