.pageContainer {
  position: relative;
  min-height: 100vh;
  background-color: #f2f2f2;
}

.backgroundImage {
  position: fixed;
  top: 0;
  width: 100%;
}

.headingContainer {
  position: fixed;
  top: 438px;
  left: 166px;
  line-height: 160px;
  color: #f2f2f2;
  font-family: NeutrafaceDJCBoldItalic;
}

.headingContainer h1,
.headingContainer h2 {
  font-size: 160px;
}

.blueText {
  color: #00a3e0;
}

.pageContent {
  margin: 0 177px 0px 176px;
  padding-top: 1093px;
  padding-bottom: 160px;
}

.cardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 42px;
}

.card {
  padding: 68px;
  background-color: #041e42;
  border-radius: 10px;
}

.card:nth-child(1),
.card:nth-child(4),
.card:nth-child(7) {
  grid-column: auto / span 2;
}

.card h2 {
  font-size: 71px;
  line-height: 73px;
  color: #00a3e0;
  font-family: NeutrafaceDJCBold;
  text-transform: uppercase;
  margin-bottom: 47px;
}

.card p {
  font-size: 47px;
  line-height: 73px;
  color: #fff;
  font-family: NeutrafaceDJCBook;
}
