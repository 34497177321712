.videoBox {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  z-index: 1;
}

.closeButton {
  position: absolute;
  top: 850px;
  left: 1650px;
  background: none;
  border: none;
}

.videoContainer {
  position: relative;
  height: 1920px;
  width: 1080px;
}

.videoControls {
  background-color: #041e42cc;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.rewindIcon,
.pauseIcon,
.muteIcon,
.playIcon,
.unmuteIcon {
  position: absolute;
  height: 150px;
  filter: invert(1);
  opacity: 0.8;
}

.playIcon {
  left: calc(50% - 56px);
  top: calc(50% - 75px);
}

.pauseIcon {
  left: calc(50% - 46.5px);
  top: calc(50% - 75px);
}

.rewindIcon {
  top: calc(50% - 75px);
  left: calc(30% - 75px);
}

.muteIcon {
  top: calc(50% - 75px);
  left: calc(70% - 84px);
}

.unmuteIcon {
  top: calc(50% - 75px);
  left: calc(70% - 83.5px);
}

.accessibleCloseButton {
  position: fixed;
  bottom: 30px;
  left: 30px;
  padding: 30px;
  border-radius: 50%;
  border: solid 3px #00a3e0;
  background-color: #33333333;
}
