.pageContainer {
  position: relative;
}

.headerContainer {
  position: fixed;
}

.backgroundImage {
  position: relative;
  /* top: 280px; */
  width: 100vw;
}

.headingContainer {
  position: absolute;
  top: 588px;
  left: 166px;
  line-height: 160px;
  color: #fff;
  font-family: NeutrafaceDJCBoldItalic;
}

.headingContainer h1 {
  text-transform: uppercase;
  font-size: 160px;
}

.blueText {
  color: #00a3e0;
}

.partners {
  margin: 0 166px;
  padding-top: 1209px;
  padding-bottom: 175px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 34px;
}

.partner {
  height: 580px;
  width: 580px;
  background-color: #041e42;
  border: 3px solid #00a3e0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
