.scrollContainer {
  position: fixed;
  bottom: -250px;
  width: 500px;
  padding-top: 50px;
  height: 500px;
  background-color: #00a3e033;
  border: solid 5px #00a3e0;
  left: 37.5%;
  border-radius: 50%;
  transform: translateY(250px);
}

.scrollIcon {
  position: absolute;
  top: calc(25% - 50px);
  left: calc(50% - 50px);
}

.scrollIcon img {
  opacity: 0.9;
  height: 100px;
}

.bounce {
  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.hide {
  animation-duration: 1s;
  animation-name: hide;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.show {
  animation-delay: 300ms;
  animation-duration: 1s;
  animation-name: show;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(10px);
  }
}

@keyframes hide {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(250px);
  }
}

@keyframes show {
  0% {
    transform: translateY(250px);
  }

  100% {
    transform: translateY(0);
  }
}
