.pageContainer {
  position: relative;
  min-height: 100vh;
}

.headerContainer {
  position: fixed;
  z-index: 9999;
}

.headerImage {
  width: 100vw;
}

.titleContainer {
  position: absolute;
  top: 480px;
  left: 166px;
}

.contentContainer {
  padding-top: 1306px;
  padding-left: 166px;
  padding-right: 176px;
}

.textContent h2 {
  text-transform: uppercase;
  font-family: NeutrafaceDJCBold;
  font-size: 77px;
  margin-bottom: 100px;
}

.textContent p {
  font-size: 60px;
  line-height: 90px;
  font-family: NeutrafaceDJCLight;
  margin-bottom: 100px;
}

.image_videoContainer {
  margin-top: 150px;
}

.experienceSection {
  margin-top: 151px;
  padding-top: 151px;
  padding-left: 166px;
  position: relative;
}

.experienceSectionContent {
  position: relative;
}

.experienceSectionImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.experienceSection h3 {
  font-family: NeutrafaceDJCBold;
  text-transform: uppercase;
  font-size: 77px;
  margin-bottom: 25px;
}

.experienceSection p {
  font-family: NeutrafaceDJCLight;
  font-size: 67px;
  color: #fff;
}

.blueText {
  color: #00a3e0;
}

.experienceCardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 58px;
  grid-template-columns: 1fr 1fr;
  margin-top: 98px;
}

.experienceCardContainer .card {
  background-color: #00a3e0;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  max-width: 890px;
}

.experienceCardContainer img {
  border-radius: 10px 0 10px 0;
  width: 100%;
}

.cardTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 549px);
  min-height: 390px;
  max-height: 549px;
  padding: 50px 0;
}

.cardTextContainer h4 {
  font-size: 60px;
  font-family: NeutrafaceDJCBook;
  max-width: 650px;
}

.cardTextContainer p {
  margin-top: 10px;
  font-size: 60px;
  font-family: NeutrafaceDJCLight;
  max-width: 730px;
}
