.pageContainer {
  position: relative;
}

.headingContainer h1 {
  font-size: 119px;
  color: #fff;
  font-family: NeutrafaceDJCBoldItalic;
  text-transform: uppercase;
  margin-bottom: 60.5px;
}

.blueText {
  color: #00a3e0;
}

.williamsDrivers {
  position: relative;
  padding: 398px 165px 121px 165px;
}

.academyDrivers {
  position: relative;
  padding: 121px 165px;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.driversContainer img {
  width: 100%;
  border-radius: 10px;
  margin: 30.25px 0;
}

.academyDrivers .backgroundImage {
  width: 100%;
}
