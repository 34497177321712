.sectionContainer {
  position: relative;
  background-color: #041e42;
}

.pageHeader {
  position: fixed;
  padding-top: 280px;
}

.profileImage {
  width: 100%;
}

h1 {
  font-size: 159px;
  line-height: 159px;
  font-family: NeutrafaceDJCBoldItalic;
  text-transform: uppercase;
  color: #fff;
}

.blueText {
  color: #00a3e0;
}

.driverName {
  position: absolute;
  top: 1030px;
  left: 166px;
}

.driverName::after {
  content: "";
  display: block;
  position: relative;
  padding-top: 90px;
  width: 50%;
  border-bottom: 3px solid #e40046;
}

.driverBio {
  padding: 1738px 166px 0 166px;
}

.driverBio h2 {
  font-size: 75px;
  color: #00a3e0;
  font-family: NeutrafaceDJCBold;
  text-transform: uppercase;
}

.driverBio p {
  font-size: 60px;
  font-family: NeutrafaceDJCLight;
  color: #fff;
  white-space: pre-wrap;
  padding-top: 107px;
  line-height: 90px;
}

.mediaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftArrow {
  position: absolute;
  left: 45px;
}

.rightArrow {
  position: absolute;
  right: 45px;
}

.imageGallery {
  background-color: #00a3e011;
  width: 1818px;
  height: 1028px;
  margin-top: 107px;
  margin-bottom: 107px;
}

.imageGallery img {
  width: 100%;
}
