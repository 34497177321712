.container {
  min-height: 100vh;
  background-color: #fff;
}

.backgroundContainer {
  position: relative;
  overflow: hidden;
}

.backgroundImage {
  width: 100%;
}

.backgroundSmoke {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  animation: infinite 30s 2.5s smoke linear;
  object-fit: cover;
}

.textImage {
  position: absolute;
  top: 451.26px;
  left: 329.1px;
  animation: forwards 3s 1s fadeIn ease-out;
  opacity: 0;
  transform: scale(0.95);
}

.navigation {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 80.1px;
  margin-left: 148px;
  padding-right: 148px;
}

@keyframes smoke {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  8% {
    opacity: 0.25;
  }

  100% {
    opacity: 0;
    transform: scale(1.5) rotate(10deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.splitTextContainer {
  position: absolute;
  bottom: 147.5px;
  width: 100%;
  font-size: 100px;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
}

.splitText {
  position: relative;
  color: transparent;
  cursor: pointer;
  font-family: NeutrafaceDJCBoldItalic;
}

.splitText .mask {
  display: block;
  position: absolute;
  overflow: hidden;
  color: #ffffff33;
  top: 0;
  height: 49%;
  transform: translateX(-200%);
  animation: splitIn 0.75s forwards 3s;
}

.mask span {
  display: block;
  animation: pulse 2.5s infinite 4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.mask + .mask {
  top: 48.9%;
  height: 51.1%;
  transform: translateX(200%);
}

.mask + .mask span {
  transform: translateY(-49%);
}

@keyframes splitIn {
  from {
    transform: translateX(inherit);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes pulse {
  0%,
  100% {
    color: #ffffff22;
  }

  50% {
    color: #fff;
  }
}

.scrollBarContainer {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.scrollBar {
  position: relative;
  background-color: #ddd;
  width: 80%;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
}

.scrollMarker {
  position: absolute;
  background-color: #00a3e0;
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.scrollMarker::before {
  position: absolute;
  content: "";
  left: -25px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #00a3e0;
}

.scrollMarker::after {
  position: absolute;
  left: 45px;
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #00a3e0;
}
