.videoContainer {
  position: relative;
  margin: 122px 0;
  height: 1028px;
  width: 1828px;
  background-color: #000;
}

.videoContainer video {
  height: 100%;
}

.videoPlaceholder {
  width: 100%;
  height: auto;
}

.playIconOverlay {
  position: absolute;
  top: 374px;
  left: 774px;
  pointer-events: none;
}

.videoControls {
  background-color: #041e42cc;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.rewindIcon,
.pauseIcon,
.closeIcon,
.muteIcon,
.playIcon,
.unmuteIcon {
  position: absolute;
  height: 150px;
  filter: invert(1);
  opacity: 0.8;
}

.playIcon {
  left: calc(50% - 56px);
  top: calc(50% - 75px);
}

.closeIcon {
  right: 25px;
  top: 10px;
}

.pauseIcon {
  left: calc(50% - 46.5px);
  top: calc(50% - 75px);
}

.rewindIcon {
  top: calc(50% - 75px);
  left: calc(30% - 75px);
}

.muteIcon {
  top: calc(50% - 75px);
  left: calc(70% - 84px);
}

.unmuteIcon {
  top: calc(50% - 75px);
  left: calc(70% - 83.5px);
}
