.pageContainer {
  position: relative;
  min-height: 100vh;
  background-color: #fff;
}

.backgroundImage {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.headingContainer {
  position: absolute;
  top: 438px;
  left: 166px;
  line-height: 160px;
  color: #fff;
  font-family: NeutrafaceDJCBoldItalic;
}

.headingContainer h1 {
  text-transform: uppercase;
  font-size: 160px;
}

.blueText {
  color: #00a3e0;
}

.contentContainer {
  padding-top: 889px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
}

.contentContainer img {
  margin: 0 auto;
  margin-bottom: 120px;
}

.videos {
  background-color: #f2f2f2;
  height: 1220px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 120px;
  width: 581px;
}

.videoCard img {
  width: 581px;
}

.videoCard:not(:last-child) {
  margin-right: 61px;
}

.icon {
  position: absolute;
}

.icon img {
  width: 250px;
}

.text {
  position: absolute;
  color: #fff;
  font-size: 52px;
  line-height: 60px;
  font-family: NeutrafaceDJCBold;
  bottom: 218px;
  left: 57px;
  text-transform: uppercase;
}
