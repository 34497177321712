* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: NeutrafaceDJCBold;
  src: local("NeutrafaceDJCBold"),
    url("./assets/fonts/NeutrafaceDJCText-Bold.otf");
}

@font-face {
  font-family: NeutrafaceDJCBoldItalic;
  src: local("NeutrafaceDJCBoldItalic"),
    url("./assets/fonts/NeutrafaceDJCText-BoldItalic.otf");
}

@font-face {
  font-family: NeutrafaceDJCBook;
  src: local("NeutrafaceDJCBook"),
    url("./assets/fonts/NeutrafaceDJCText-Book.otf");
}

@font-face {
  font-family: NeutrafaceDJCBookItalic;
  src: local("NeutrafaceDJCBookItalic"),
    url("./assets/fonts/NeutrafaceDJCText-BookItalic.otf");
}

@font-face {
  font-family: NeutrafaceDJCDemi;
  src: local("NeutrafaceDJCDemi"),
    url("./assets/fonts/NeutrafaceDJCText-Demi.otf");
}

@font-face {
  font-family: NeutrafaceDJCDemiItalic;
  src: local("NeutrafaceDJCDemiItalic"),
    url("./assets/fonts/NeutrafaceDJCText-DemiItalic.otf");
}

@font-face {
  font-family: NeutrafaceDJCLight;
  src: local("NeutrafaceDJCLight"),
    url("./assets/fonts/NeutrafaceDJCText-Light.otf");
}

@font-face {
  font-family: NeutrafaceDJCLightItalic;
  src: local("NeutrafaceDJCLightItalic"),
    url("./assets/fonts/NeutrafaceDJCText-LightItalic.otf");
}

html {
  height: 100%;
}

body {
  background-color: #f2f2f2;
  height: 100%;
}

.slide-enter {
  transform: translate(0, 100vh);
  z-index: 10;
  height: 100vh;
  background-color: #f2f2f2;
}

.slide-enter.slide-enter-active {
  transform: translate(0, 0);
  z-index: 10;
  height: 100vh;
  background-color: #f2f2f2;
  transition: transform 0.3s ease;
}

.slide-exit {
  height: 100vh;
  background-color: #f2f2f2;
  transform: translate(0, 0);
  z-index: -9999;
  opacity: 1;
}

.slide-exit.slide-exit-active {
  position: absolute;
  height: 100vh;
  background-color: #f2f2f2;
  inset: 0 0 0 0;
  z-index: -9999;
  transform: translate(0, -250px);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.1;
}

/* Slide Left Transitions */
.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-enter.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 0.5s;
}

.slide-left-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-left-exit.slide-left-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.5s;
}

/* Slide Right Transitions */
.slide-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-right-enter-done {
  opacity: 1;
  transform: translateX(0%);
}

.slide-right-enter.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 0.5s;
}
.slide-right-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-right-exit.slide-right-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s;
}

/* Fade Transitions */
.fade-enter {
  position: absolute;
  opacity: 0;
}

.fade-enter.fade-enter-active {
  position: absolute;
  opacity: 1;
  transition: all 0.5s;
}
.fade-exit {
  position: absolute;
  opacity: 1;
}

.fade-exit.fade-exit-active {
  position: absolute;
  opacity: 0;
  transition: all 0.5s;
}

/* Slide Relative Transitions */
.slide-relative-enter {
  opacity: 0;
  transform: translateX(-3000px);
}

.slide-relative-enter.slide-relative-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 0.5s ease;
}

.slide-relative-enter-done {
  transform: translateX(3000px) !important;
  transition: all 0.5s ease;
}

.slide-relative-exit {
  opacity: 1;
  transform: translateX(0px);
}

.slide-relative-exit.slide-relative-exit-active {
  opacity: 0;
  transform: translateX(-3000px);
  transition: all 0.5s;
}

/* Fade Relative Transitions */
.fade-relative-enter {
  opacity: 0;
}

.fade-relative-enter.fade-relative-enter-active {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.fade-relative-enter-done {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.fade-relative-exit {
  opacity: 1;
}

.fade-relative-exit.fade-relative-exit-active {
  opacity: 0;
  transition: all 0.5s ease-out;
}
