.flipCardInner {
  position: relative;
  height: 896px;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border: #00a3e0 3px solid;
  border-radius: 10px;
}

.flipped {
  transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flipCardBack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 59px;
  line-height: 69px;
  font-family: NeutrafaceDJCBold;
  text-transform: uppercase;
  color: #fff;
  object-fit: contain;
  overflow: hidden;
  height: 100%;
}

.flipCardBack img {
  position: absolute;
  z-index: -1;
  height: 100%;
}

.flipCardBack .icon {
  position: absolute;
  top: 265px;
  max-height: 144px;
}

.flipCardBack p {
  position: absolute;
  top: 495px;
  padding: 0 50px;
}

.flipCardFront {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00a3e0;
  color: white;
  transform: rotateY(180deg);
  font-size: 59px;
  line-height: 80px;
  padding: 33px;
  white-space: pre-wrap;
}

.cardWithHeading {
  text-align: left;
}

.cardWithHeading h2 {
  font-size: 72px;
  font-family: NeutrafaceDJCBold;
}

.cardWithHeading p {
  margin-top: 3rem;
  font-size: 48px;
  font-family: NeutrafaceDJCLight;
  line-height: 72px;
}

.cardWithoutHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: NeutrafaceDJCBook;
}
