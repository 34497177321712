.pageContainer {
  position: relative;
  height: 100vh;
  background: #fff;
}

.pageHeader {
  z-index: 9999;
}

.backgroundImage {
  position: absolute;
  width: 100%;
}

.headingContainer {
  position: absolute;
  top: 596px;
  left: 166px;
}

.headingContainer h1 {
  font-size: 160px;
  color: #fff;
  font-family: NeutrafaceDJCBoldItalic;
}

.contentContainer {
  position: relative;
  padding-top: 1306px;
  margin-left: 166px;
}

.contentContainer h2 {
  color: #00a3e0;
  font-size: 75px;
  text-transform: uppercase;
  font-family: NeutrafaceDJCBold;
}

.contentContainer p {
  font-size: 60px;
  line-height: 90px;
  font-family: NeutrafaceDJCLight;
  max-width: 1664px;
  margin-top: 90px;
}

.videoContainer {
  margin-top: 250px;
  width: 1818px;
}

.videoContainer video {
  width: 100%;
}
