.pageContainer {
  position: relative;
  background-color: #041e42;
  min-height: 100vh;
}

.headerContainer {
  position: fixed;
}

.headerImage {
  width: 100vw;
}

.titleContainer {
  position: absolute;
  top: 576px;
  left: 166px;
}

.contentContainer {
  padding-top: 1306px;
  padding-left: 166px;
  padding-right: 176px;
}

.textContent h2 {
  text-transform: uppercase;
  font-family: NeutrafaceDJCBold;
  font-size: 77px;
  margin-top: 158px;
  margin-bottom: 100px;
}

.textContent p {
  color: #fff;
  font-size: 60px;
  font-family: NeutrafaceDJCLight;
  margin-bottom: 100px;
}

.blueText {
  color: #00a3e0;
}
