.navCard {
  position: relative;
  margin-right: 50px;
  height: 622px;
  min-width: 801px;
  border-radius: 10px;
}

.navCard img {
  height: 100%;
  border: 5px solid #fff;
  border-radius: 20px;
}

.cardText {
  position: absolute;
  top: 410px;
  left: 66px;
}

.cardText p {
  font-family: NeutrafaceDJCBoldItalic;
  color: #fff;
  font-size: 80px;
  line-height: 80px;
}

.cardText :nth-child(2) {
  color: #00a3e0;
}

.pulseActive {
  animation: pulse 1s ease-out infinite;
}

@keyframes pulse {
  0% {
    border: 5px solid red;
  }

  100% {
    border: 5px solid #fff;
  }
}
