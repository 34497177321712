.pageContainer {
  position: relative;
}

.pageHeader {
  position: fixed;
  z-index: 2;
}

.backgroundImage {
  top: 0;
  width: 100vw;
}

.headingContainer {
  position: absolute;
  top: 438px;
  left: 166px;
  line-height: 160px;
  color: #f2f2f2;
  font-family: NeutrafaceDJCBoldItalic;
}

.headingContainer h1,
.headingContainer h2 {
  font-size: 160px;
}

.blueText {
  color: #00a3e0;
}

.pageContent {
  margin: 0 172px 172px 172px;
  padding-top: 1023px;
  padding-bottom: 172px;
}
