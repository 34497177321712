.pageContainer {
  position: relative;
  height: 100vh;
  background-color: #f2f2f2;
}

.backgroundImage {
  position: absolute;
  top: 0;
  width: 100%;
}

.headingContainer {
  position: absolute;
  top: 438px;
  left: 166px;
  line-height: 160px;
  color: #fff;
  font-family: NeutrafaceDJCBoldItalic;
}

.headingContainer h1 {
  font-size: 160px;
}

.contentContainer {
  position: relative;
  padding-top: 834px;
  margin-left: 164px;
}

.menu {
  position: absolute;
  top: 1156px;
  left: 1400px;
  font-family: NeutraFaceDJCBold;
  font-size: 71px;
  text-transform: uppercase;
  line-height: 71px;
  color: #041e42;
}

.menu ul {
  list-style: none;
}

.menu li {
  margin-bottom: 71px;
}

.safetyImage img {
  margin-bottom: -4px;
}

.activeMenu {
  color: #00a3e0;
}

.textContainer {
  background-color: #041e42;
  color: #fff;
  width: 1332px;
  height: 882px;
  padding: 111px 127px 107px 106px;
}

.textContainer h2 {
  font-size: 67px;
  font-family: NeutraFaceDJCBold;
  color: #00a3e0;
  text-transform: uppercase;
}

.textContainer p {
  font-family: NeutraFaceDJCBook;
  line-height: 73px;
  font-size: 47px;
  margin-top: 75px;
}
