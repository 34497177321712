.pageContainer {
  position: relative;
  background-color: #fff;
  height: 100vh;
}

.backgroundImage {
  position: absolute;
  top: 0;
  width: 100%;
}

.headingContainer {
  position: absolute;
  top: 438px;
  left: 166px;
  line-height: 160px;
  color: #fff;
  font-family: NeutrafaceDJCBoldItalic;
}

.headingContainer h1 {
  text-transform: uppercase;
  font-size: 160px;
}

.blueText {
  color: #00a3e0;
}

.contentContainer {
  position: relative;
  padding-top: 825px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 111px;
  width: 1813px;
  margin: 0 auto;
}

.button {
  width: 889px;
  height: 132px;
  font-family: NeutrafaceDJCBold;
  text-transform: uppercase;
  font-size: 59px;
  color: #fff;
  border-radius: 10px;
  border: none;
  background-color: #041e42;
}

.active {
  background-color: #00a3e0;
}

.table {
  margin: 60px 181px;
  color: #041e42;
  text-transform: uppercase;
  font-size: 37.5px;
  font-family: NeutrafaceDJCBold;
  width: 1813px;
  border-collapse: collapse;
}

.thead tr th {
  padding: 0 0 40px 0;
  text-align: left;
}

.tbody tr td {
  padding: 35px 0;
}

.tbody tr td:nth-child(1) {
  padding-left: 40px;
}

.tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.tbody tr:nth-child(even) {
  background-color: #fafafa;
}

.highlight {
  color: #00a3e0;
}

.logoTeam {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 245px;
  overflow: hidden;
}

.table .thead .teamHeading {
  text-align: center;
}

.logoTeam img {
  height: 150%;
}
