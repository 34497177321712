.pageContainer {
  position: relative;
  background-color: #f86e6e;
}

.backgroundContainer {
  position: absolute;
  top: 280px;
  height: 3560px;
  overflow: hidden;
}

.backgroundImage {
  width: 100%;
}

.headingContainer {
  position: absolute;
  top: 438px;
  left: 166px;
  line-height: 160px;
  color: #fff;
  font-family: NeutrafaceDJCBoldItalic;
  text-transform: uppercase;
}

.headingContainer h1,
.headingContainer h2 {
  font-size: 160px;
}

.blueText {
  color: #00a3e0;
}

.contentContainer {
  position: absolute;
  margin-top: 554px;
  width: 100%;
}

.steeringWheelButtons {
  position: relative;
  top: 260px;
  height: 50%;
}

.button {
  position: absolute;
  background-color: transparent;
  border-radius: 50%;
  height: 100px;
  aspect-ratio: 1/1;
  box-shadow: 0 0 0.9rem #fff, 0 0 0.9rem #fff, 0 0 1.5rem #fff, 0 0 0.1rem #fff,
    0 0 1rem #fff, inset 0 0 0.5rem #fff;
  opacity: 0;
}

.buttonFlicker {
  animation: flicker 1.5s linear forwards;
}

.buttonBlink {
  opacity: 1;
  animation: flashAnimation 2s infinite 2s;
}

.activeButton {
  box-shadow: 0 0 0.9rem #00a3e0, 0 0 0.9rem #00a3e0, 0 0 1.5rem #00a3e0,
    0 0 0.1rem #00a3e0, 0 0 1rem #00a3e0, inset 0 0 0.5rem #00a3e0;
}

.selectorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  animation-delay: 3s;
  opacity: 0;
  animation: fadeIn 1s linear forwards 3s;
  margin-top: 2615px;
}

.selectorContainer .textContainer {
  border: 5px solid #00a3e0;
  border-radius: 10px;
  padding: 50px 0;
  width: 1320px;
}

.selectorContainer .textContainer h3 {
  font-size: 69px;
  font-family: NeutrafaceDJCBold;
  color: #00a3e0;
  text-transform: uppercase;
  text-align: center;
}

@keyframes flashAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flicker {
  0%,
  90% {
    opacity: 1;
  }
  20%,
  22% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
