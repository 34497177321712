.pageContainer {
  position: relative;
  background-color: #fff;
  min-height: 100vh;
}

.headerContainer {
  position: fixed;
  z-index: 9999;
}

.headerImage {
  width: 100vw;
}

.titleContainer {
  position: absolute;
  top: 438px;
  left: 166px;
}

.appCallToAction {
  padding-left: 166px;
  padding-right: 176px;
}

.appCallToAction h3 {
  margin: 122px 0 57px 0;
  font-size: 75px;
  text-transform: uppercase;
  font-family: NeutrafaceDJCBold;
}

.appCallToAction p {
  margin-bottom: 122px;
  font-size: 60px;
  font-family: NeutrafaceDJCLight;
}

.contentContainer {
  padding-top: 1000px;
}

.callToActionBanner {
  position: relative;
  margin-top: -4px;
}

.callToActionBanner .qrCode {
  position: absolute;
  top: 88px;
  left: 192px;
  background-color: #fff;
  height: 267px;
  width: 267px;
  padding: 25px;
  border-radius: 10px;
}

.callToActionBanner .qrCode img {
  width: 100%;
}

.callToActionBanner h2 {
  position: absolute;
  top: 109px;
  left: 568px;
  font-size: 68px;
  line-height: 79px;
  text-transform: uppercase;
  font-family: NeutrafaceDJCBold;
  color: #fff;
  max-width: 1063px;
}

.appGallery {
  display: flex;
  overflow-y: auto;
  gap: 25px;
  background-color: #f2f2f2;
  padding: 76px 175px 132px 0;
}

.appGallery img {
  height: 1176px;
}

.appGallery img:nth-child(1) {
  margin-left: 175px;
}

.bottomSection {
  padding: 86px 182px 168px 166px;
}

.bottomSection h3 {
  margin-top: 132px;
  font-size: 60px;
  font-family: NeutrafaceDJCBold;
  text-transform: uppercase;
}

.bottomSection p {
  font-size: 60px;
  font-family: NeutrafaceDJCLight;
}

.blueText {
  color: #00a3e0;
}
