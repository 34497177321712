.pageContainer {
  position: relative;
}

.headerContainer {
  position: fixed;
  z-index: 9999;
}

.headerImage {
  width: 100vw;
}

.titleContainer {
  position: absolute;
  top: 576px;
  left: 166px;
}

.locationOverview {
  padding-top: 1300px;
  padding-left: 166px;
  padding-bottom: 260px;
  background-color: #fff;
}

.locationOverview h2 {
  font-family: NeutrafaceDJCBold;
  text-transform: uppercase;
  font-size: 109px;
  color: #041e42;
}

.locationOverview h2::after {
  content: "";
  display: block;
  position: absolute;
  padding-top: 50px;
  width: 180px;
  border-bottom: 2px solid #e40046;
}

.locationInfo h3 {
  font-family: NeutrafaceDJCBold;
  text-transform: uppercase;
  font-size: 77px;
}

.locationInfo {
  display: grid;
  grid-template-areas: "location hours" "dates hours";
  margin-top: 200px;
}

.locationInfo .block1 {
  grid-area: location;
  line-height: 100px;
}

.locationInfo .block2 {
  margin-top: auto;
  grid-area: dates;
}

.locationInfo .block3 {
  grid-area: hours;
}

.locationInfo .block3 p {
  position: relative;
  width: 100%;
  margin-right: 400px;
  line-height: 100px;
}

.locationInfo .block3 p span {
  position: absolute;
  font-family: NeutrafaceDJCBook;
  padding-left: 130px;
  left: 300px;
}

.locationInfo p {
  font-family: NeutrafaceDJCLight;
  font-size: 67px;
}

.elementsSection {
  position: relative;
  padding-top: 151px;
  padding-left: 166px;
}

.elementSectionContent {
  position: relative;
}

.elementSectionImage {
  position: absolute;
  top: 0;
  left: 0;
}

.elementsSection h3 {
  font-family: NeutrafaceDJCBold;
  text-transform: uppercase;
  font-size: 77px;
  margin-bottom: 25px;
}

.elementsSection p {
  font-family: NeutrafaceDJCLight;
  font-size: 67px;
  color: #fff;
}

.blueText {
  color: #00a3e0;
}

.elementsCardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 56px;
  grid-template-columns: 1fr 1fr;
  margin-top: 98px;
}

.elementsCardContainer .card {
  background-color: #00a3e0;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  max-width: 890px;
}

.elementsCardContainer img {
  border-radius: 10px 0 10px 0;
  width: 100%;
}

.cardTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 549px);
  min-height: 390px;
  padding: 50px 0;
}

.cardTextContainer h4 {
  font-size: 60px;
  font-family: NeutrafaceDJCBook;
  max-width: 800px;
}

.cardTextContainer p {
  margin-top: 10px;
  font-size: 60px;
  font-family: NeutrafaceDJCLight;
  max-width: 800px;
}
